import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ComingSoonPage = (): JSX.Element => (
  <Layout>
    <SEO title="Coming soon" />
    <div className="coming-soon">
      <h1>Coming soon</h1>
    </div>
  </Layout>
)

export default ComingSoonPage
